import { NgModule } from '@angular/core';

import { SafePipeModule } from 'src/app/business/common/pipes/safe-pipe/safe-pipe.module';
import { HeaderComponent } from './header.component';
import { ReferenceServiceModule } from 'src/app/business/export/services/reference/reference.service.module';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from 'src/app/app-routing.module';


@NgModule({
    declarations: [
        HeaderComponent,
    ],
    imports: [
        CommonModule,
        SafePipeModule,
        ReferenceServiceModule,
        AppRoutingModule
    ],
    exports: [
        HeaderComponent
    ]
})
export class HeaderModule { }
