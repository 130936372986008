import { ErrorHandler, Inject, Injectable, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { FooterComponent } from './presentation/layout/footer/footer.component';
import { PageLayoutComponent } from './presentation/layout/page-layout/page-layout.component';
import { FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AppVersion } from 'src/environments/app-version';
import * as Rollbar from 'rollbar';
import { ErrorHandlerService } from './services/error-handler.service';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { HeaderModule } from './presentation/layout/header/header.module';

const rollbarConfig = {
    accessToken: environment.ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: environment.ROLLBAR_ENV,
    payload: {
        client: {
            javascript: {
                code_version: AppVersion.appVersion
            }
        }
    }
};

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
    constructor(@Inject(RollbarService)
    private rollbar: Rollbar,
        private errorHandlerService: ErrorHandlerService
    ) { }

    handleError(err: any): void {
        this.errorHandlerService.handleError(this.rollbar, err.originalError || err);
    }
}

export function rollbarFactory() {
    return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        PageLayoutComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        HeaderModule,
        RecaptchaV3Module,
    ],
    providers: [
        ErrorHandlerService,
        { provide: ErrorHandler, useClass: RollbarErrorHandler },
        { provide: RollbarService, useFactory: rollbarFactory },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.recaptcha.siteKey,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
