import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageLayoutComponent } from './presentation/layout/page-layout/page-layout.component';


const routes: Routes = [
  {
    path: '',
    component: PageLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./presentation/pages/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'export',
        loadChildren: () => import('./presentation/pages/export/export.module').then(m => m.ExportModule),
      },
      {
        path: 'export/resume',
        loadChildren: () => import('./presentation/pages/resume-export/resume-export.module').then(m => m.ResumeExportModule),
      },
      {
          path: `export/resume/:email/:referenceNumber`,
          loadChildren: () => import('./presentation/pages/resume-export/resume-export.module').then(m => m.ResumeExportModule),
      },
      {
        path: 'pricing',
        loadChildren: () => import('./presentation/pages/pricing/pricing.module').then(m => m.PricingModule),
      },
      {
        path: 'about',
        loadChildren: () => import('./presentation/pages/about-us/about-us.module').then(m => m.AboutUsModule),
      },
      {
        path: 'privacypolicy',
        loadChildren: () => import('./presentation/pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
      },
      {
        path: 'termsconditions',
        loadChildren: () => import('./presentation/pages/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule),
      },
      {
        path: 'faq',
        loadChildren: () => import('./presentation/pages/faq/faq.module').then(m => m.FAQModule),
      },
      {
        path: 'contact',
        loadChildren: () => import('./presentation/pages/contact-us/contact-us.module').then(m => m.ContactUsModule),
      },
      {
        path: '**',
        pathMatch: 'full',
        loadChildren: () => import('./presentation/pages/not-found/not-found.module').then(m => m.NotFoundModule),
      },
    ]
  },

  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
