import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReferenceApiService } from './reference-api.service';



@NgModule({
  providers: [
    ReferenceApiService,
  ],
  imports:[
    HttpClientModule,
  ]
})
export class ReferenceApiServiceModule { }
