<footer>
    <div class="footer__navigation">
        <div class="container-grid">
            <div>
                <h3>AutoExports</h3>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><a routerLink="/pricing">Pricing</a></li>
                    <li><a routerLink="/about">About us</a></li>
                    <li><a routerLink="/privacypolicy">Privacy policy</a></li>
                </ul>
            </div>

            <div>
                <h3>Export</h3>
                <ul>
                    <li><a routerLink="/export">Start export</a></li>
                    <li><a routerLink="/export/resume">Resume export</a></li>
                </ul>
            </div>

            <div>
                <h3>Help</h3>
                <ul>
                    <li><a routerLink="/faq">FAQ</a></li>
                    <!-- <li><a routerLink="/">Live chat</a></li> -->
                    <li><a routerLink="/contact">Contact us</a></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="footer__copyright">
        <p>Powered by <a href="https://www.smartborder.com"
               target="_blank"
               rel="noopener noreferrer">SmartBorder</a>
               <br>
                <span>Build version: {{ appVersion }} </span>
             </p>
    </div>
</footer>
