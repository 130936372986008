
export class VehicleTypeTariff {

    displayOrder: number;
    name: string;
    parentRefTariffID: number;
    refTariffID: number;
    tariffNum: string;

    constructor() {
    }
}
