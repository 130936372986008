import { NgModule } from '@angular/core';
import { ReferenceApiServiceModule } from 'src/app/business/api/reference.service/reference-api.service.module';
import { ReferenceService } from './reference.service';


@NgModule({
  providers: [
      ReferenceService,
  ],
  imports:[
    ReferenceApiServiceModule,
  ]
})
export class ReferenceServiceModule { }


