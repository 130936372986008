import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class ReferenceApiService {


    configUrl = `${environment.AES_API_URL}/AutoExports`;
    constructor(
        private http: HttpClient
    ) { }

    public getAESPortsInformation = () => {
        return this.http.get<any>(this.configUrl + '/GetAESPortsInformation');
    }

    public getAESVehicleTypeTariffs = () => {
        return this.http.get<any>(this.configUrl + '/GetAESVehicleTypeTariffs');
    }

    public getAESProvincesAndStates = () => {
        return this.http.get<any>(this.configUrl + '/GetAESProvincesAndStates');
    }

    public getLocation = () => {
        return this.http.get<any>(this.configUrl + '/GetLocation');
    }

    public getNoticeBarText = () => {
        return this.http.get<any>(this.configUrl + '/GetNoticeBarText');
    }
}
