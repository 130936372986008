import { Component, HostListener, OnInit } from '@angular/core';
import {
  animate,
  style,
  state,
  transition,
  trigger,
} from '@angular/animations';
import { ReferenceService } from 'src/app/business/export/services/reference/reference.service';

@Component({
  selector: 'aes-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('stateAnnouncement', [
      state('show', style({
        height: 30,
        padding: '0.4375rem',
        opacity: 1,
        zIndex: 1
      })),
      state('hide', style({
        height: 0,
        padding: '0rem',
        opacity: 0,
        zIndex: -1
      })),
      transition('show => hide', animate('250ms ease-out')),
    ])
  ]
})
export class HeaderComponent implements OnInit {

  mobileMenuIsOpen = false;
  showAnnouncement = false;
  noticeBarText: string = '';

  private windowWidth: any;
  private mobileResolution = 768;

  constructor(
    private referenceService: ReferenceService,) {
  }

  @HostListener('window:resize', ['$event'])

  ngOnInit(): void {
    this.getScreenSize();
    this.showAnnouncement = !localStorage.getItem('showAnnouncement');
    this.referenceService.getNoticeBarText().subscribe(text => {
      if (!!text) {
        this.showAnnouncement = true;
        this.noticeBarText = text;
      } else {
        this.showAnnouncement = false;
      }
    })
  }

  get stateAnnouncement() {
    return this.showAnnouncement ? 'show' : 'hide'
  }

  toggleAnnouncement() {
    this.showAnnouncement = !this.showAnnouncement
  }

  openMobileMenu() {
    const htmlSelector = this.getHtmlSelector();

    if (this.windowWidth <= this.mobileResolution) {
      this.mobileMenuIsOpen = !this.mobileMenuIsOpen

      if (this.mobileMenuIsOpen) {
        htmlSelector.classList.add('no-scroll')
      } else {
        htmlSelector.classList.remove('no-scroll')
      }

    }
    else {
      this.mobileMenuIsOpen = false
    }
  }

  addSmoothScroll() {
    const htmlSelector = this.getHtmlSelector();
    htmlSelector.classList.add('scroll-smooth')
    setTimeout(() => {
      htmlSelector.classList.remove('scroll-smooth');
    }, 1000);
  }

  private getScreenSize() {
    const htmlSelector = this.getHtmlSelector();

    this.windowWidth = window.innerWidth;

    if (this.windowWidth > this.mobileResolution) {
      this.mobileMenuIsOpen = false
      htmlSelector.classList.remove('no-scroll')
    }
  }

  private getHtmlSelector() {
    return document.querySelector('html') as HTMLElement;
  }
}
