import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  ngOnInit() {
    this.loadScript();
}

private loadScript() {
  setTimeout(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `const stripe = Stripe('${environment.STRIPE_API_KEY}'); const elements = stripe.elements();`;
    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(script);
    }
  }, 3000)
}
}
