import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import * as Rollbar from 'rollbar';



@Injectable()
export class ErrorHandlerService {
    private injector: Injector;
    private get router(): Router {
        return this.injector.get(Router);
    }

    constructor(injector: Injector,) {
        this.injector = injector;
    }

    public handleError(rollbar: Rollbar, error: any): void {
        rollbar.error(error);
    }
}
