import { Component, OnInit } from '@angular/core';

import { AppVersion } from 'src/environments/app-version';

@Component({
  selector: 'aes-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {

  appVersion = '';
  
  constructor() { }

  ngOnInit(): void {
    this.appVersion = AppVersion.appVersion;
  }
}
