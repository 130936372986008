
export class RefPickListData {
    code: string;
    context: string;
    description: string;
    displayOrder: number;
    name: string;
    originalDisplayOrder: number;
    refPicklistDataID: number;

    constructor() {
    }
}