import { Injectable } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { ReferenceApiService } from 'src/app/business/api/reference.service/reference-api.service';
import { PortInformation } from '../../models/port-information';
import { RefPickListData } from '../../models/ref-picklist-data.model';
import { VehicleTypeTariff } from '../../models/vehicle-type-tariff.model';

@Injectable()
export class ReferenceService {

  public portsInformation: PortInformation[];
  public vehicleTypeTariffs: VehicleTypeTariff[];
  public states: RefPickListData[];
  public provinces: RefPickListData[];

  
  public static provinces$: Subject<RefPickListData[]> = new Subject<RefPickListData[]>();
  public static states$: Subject<RefPickListData[]> = new Subject<RefPickListData[]>();

  constructor(
    private referenceApiService: ReferenceApiService,) { }


    getLocation = () => {
      return this.referenceApiService.getLocation()
    }


  getContexts() {
    const getPorts = this.referenceApiService.getAESPortsInformation();
    const vehicleTypeTariffs = this.referenceApiService.getAESVehicleTypeTariffs();
    const provincesAndState = this.referenceApiService.getAESProvincesAndStates();

    forkJoin({ getPorts: getPorts, vehicleTypeTariffs: vehicleTypeTariffs, provincesAndState: provincesAndState }).subscribe((results: any) => {
      this.mapPortsInformation(results.getPorts.Export_AESPorts, results.getPorts.Export_AESPortType);
      this.mapVehicleTypeTariffs(results.vehicleTypeTariffs.Export_VehicleTariffRef);
      this.mapProvinces(results.provincesAndState.RefPicklistData.filter((data: any) => data.Context == 'PROVINCES'));
      this.mapStates(results.provincesAndState.RefPicklistData.filter((data: any) =>  data.Context.trim() == 'STATES'));
    })
  }

  getNoticeBarText() {
    return this.referenceApiService.getNoticeBarText()
  }

  private mapPortsInformation(ports: any, types: any) {
    this.portsInformation = ports.map((x: any) => {

      const port: PortInformation = new PortInformation();
      const type = types.find((type: any) => type.AESPortTypeKey == x.PortTypeKey);

      port.portName = x.PortName;
      port.portCode = x.PortCode;
      port.email = x.Email;
      port.phoneNumber = x.PhoneNumber;
      port.url = x.Url;
      port.openOnWeekends = type.OpenOnWeekends;
      port.workingDayOnWeekends = type.WorkingDayOnWeekends;
      return port;

    });
  }

  private mapVehicleTypeTariffs(vehicleTypeTariffs: any) {
    const sortedVehicleTypeTariffs = vehicleTypeTariffs.map((x: any) => {

      const vehcileTypeTariff = new VehicleTypeTariff();

      vehcileTypeTariff.name = x.Name;
      vehcileTypeTariff.displayOrder = x.DisplayOrder;
      vehcileTypeTariff.tariffNum = x.TariffNum;
      vehcileTypeTariff.refTariffID = x.RefTariffID;
      vehcileTypeTariff.parentRefTariffID = !!x.ParentRefTariffID ? x.ParentRefTariffID : 0;

      return vehcileTypeTariff;

    }).sort((a: any, b: any) => {
        return a.displayOrder - b.displayOrder;
    });

    this.vehicleTypeTariffs = sortedVehicleTypeTariffs;

  }

  private mapProvinces(provinces: any) {
    this.provinces = provinces.map((x: any) => {
      const province = this.refPickListData(x);
      return province;
    })

    ReferenceService.provinces$.next(this.provinces);
  }

  private mapStates(states: any) {
    this.states = states.map((x: any) => {
      const state = this.refPickListData(x);
      return state;
    })

    ReferenceService.states$.next(this.states);
  }

  private refPickListData(data: any) {
    const returnObj = new RefPickListData();

    returnObj.code = data.Code;
    returnObj.context= data.Contedatat;
    returnObj.description= data.Description;
    returnObj.displayOrder= data.Code;
    returnObj.originalDisplayOrder= data.Code;
    returnObj.refPicklistDataID= data.Code;
    returnObj.name= data.Code;

    return returnObj;
  }

}
