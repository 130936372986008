
export class PortInformation {

    portCode: number;
    portName: string;
    email: string;
    phoneNumber: string;
    url:string;
    openOnWeekends: boolean;
    workingDayOnWeekends: boolean;

    constructor() {
    }
}
