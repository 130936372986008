export const environment = {
  production: false,
  
  AES_API_URL: "./api6",
  STRIPE_API_KEY: "pk_test_51Kj6nWCc9SXVaR1rVJmwU7zz0ZWnl5qpyyQbNeOo4a2FsZ2v0zYCg0NA8v80PR4DQgxWzjhXvkRV0fvPjij0UbnA00WB177Iek",

  
  ROLLBAR_TOKEN: "1e07805d4df74ce6969e7adabe73de59",
  ROLLBAR_ENV: "DEV",
  recaptcha: {
      siteKey: "6Le11BIpAAAAAJJaQQF0yvcyp89UOUWiQq0GlIRA",
  },
};
